
import { useTransition, animated, config } from 'react-spring'
import { useTheme } from '/machinery/Theme'
import { useTranslate } from '/machinery/I18n'
import { handleResponse } from '/machinery/handleResponse'
import { ButtonPrimary, ButtonLink } from '/features/buildingBlocks/Button'
import { useReportError } from '/machinery/ReportError'

import styles from './CookieBar.css'

export function CookieBar({ setCookieEndpoint, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const [showCookieBar, setShowCookieBar] = React.useState(true)
  const theme = useTheme()
  const reportError = useReportError()
  const transitions = useTransition(showCookieBar, {
    from: { opacity: 0, y: '-40px' },
    enter: { opacity: 1, y: '0px' },
    leave: { opacity: 0, y: '40px' },
    config: config.gentle,
  })

  return (
    <div className={layoutClassName}>
      {transitions(
        (style, showCookieBar) => showCookieBar && (
          <animated.div {...{ style }} className={cx(styles.cookiebar, theme.background, theme.color)}>
            <div className={styles.text}>
              <p>{__`cookie-bericht`}</p>
            </div>
            <div className={styles.buttons}>
              <ButtonPrimary onClick={() => { setTracking(true) }} dataX='accept-tracking'>
                {__`cookie-akkoord`}
              </ButtonPrimary>
              <ButtonLink onClick={() => { setTracking(false) }} dataX='refuse-tracking'>
                {__`cookie-niet-akkoord`}
              </ButtonLink>
            </div>
          </animated.div>
        )
      )}
    </div>
  )

  function setTracking(accepted) {
    savePreferences({ consent: accepted }).then(
      () => {
        setShowCookieBar(false)
        trackCookieConsentGiven({ accepted })
      },
      reportError
    )
  }

  async function savePreferences({ consent }) {
    const response = await fetch(setCookieEndpoint, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ consent })
    })

    return handleResponse(response)
  }
}

function trackCookieConsentGiven({ accepted }) {
  if (window.dataLayer) window.dataLayer.push({
    event: 'cookie_consent_given',
    metadata: {
      consent: {
        anonymize_ip: !accepted,
        ads: accepted,
      },
    },
  })
}
